<template>
        <el-dialog title="Evenimente" append-to-body :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item prop="Tip" label='Tip' >
                     <el-select class='full-width' v-model='selectedObject.Tip' ><el-option label='DeBizz' value='TheBizz'></el-option><el-option label='Partener' value='Partener'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="TitleRo" label='Title Ro' >
                    <el-input  class='full-width' v-model='selectedObject.TitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="TitleEn" label='Title De' >
                    <el-input  class='full-width' v-model='selectedObject.TitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugRo" label='Slug Ro' >
                    <el-input  class='full-width' v-model='selectedObject.SlugRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SlugEn" label='Slug De' >
                    <el-input  class='full-width' v-model='selectedObject.SlugEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SubtitleRo" label='Subtitle Ro' >
                    <el-input  class='full-width' v-model='selectedObject.SubtitleRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="SubtitleEn" label='Subtitle De' >
                    <el-input  class='full-width' v-model='selectedObject.SubtitleEn' />
                </el-form-item>
            </el-col>
            <el-col :md='12'>
                <el-form-item prop="DescriptionRo" label='Description Ro' >
                    <el-input type="textarea"  class='full-width' v-model='selectedObject.DescriptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='12'>
                <el-form-item prop="DescriptionEn" label='Description De' >
                    <el-input type="textarea" class='full-width' v-model='selectedObject.DescriptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="EventDate" label='Event Date' >
                    <el-date-picker  class='full-width' v-model='selectedObject.EventDate' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Published" label='Published' >
                    <el-switch
                        v-model="selectedObject.Published"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Published"
                        inactive-text="Draft">
                    </el-switch>                
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="ContentRo" label='Content Ro' >
                    <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Evenimente/upload_image_tinymce'"
                        :show-file-list='false'
                        :on-success='handleUploadImageRoTinymce'>
                        <el-button type="info" plain>Upload image<i class="el-icon-upload el-icon-right"></i></el-button>
                    </el-upload>
                    <vue2-tinymce-editor v-model="selectedObject.ContentRo"></vue2-tinymce-editor>
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item prop="ContentEn" label='Content De' >
                    <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Evenimente/upload_image_tinymce'"
                        :show-file-list='false'
                        :on-success='handleUploadImageEnTinymce'>
                        <el-button type="info" plain>Upload image<i class="el-icon-upload el-icon-right"></i></el-button>
                    </el-upload>
                    <vue2-tinymce-editor v-model="selectedObject.ContentEn"></vue2-tinymce-editor>
                </el-form-item>
            </el-col>

            <el-col :md='24'>
                <el-form-item prop="uploadPreview_Image" label='Image' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Evenimente/upload_Image'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_Image'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_Image' :src='selectedObject.uploadPreview_Image' > 
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="ImageCaptionRo" label='Image Caption Ro' >
                    <el-input  class='full-width' v-model='selectedObject.ImageCaptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="ImageCaptionEn" label='Image Caption De' >
                    <el-input  class='full-width' v-model='selectedObject.ImageCaptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionRo" label='Meta Description Ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaDescriptionEn" label='Meta Description De' >
                    <el-input  class='full-width' v-model='selectedObject.MetaDescriptionEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsRo" label='Meta Keywords Ro' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsRo' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="MetaKeywordsEn" label='Meta Keywords De' >
                    <el-input  class='full-width' v-model='selectedObject.MetaKeywordsEn' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="LinkExtern" label='Link Extern' >
                    <el-input  class='full-width' v-model='selectedObject.LinkExtern' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="AfisamLinkFormular" label='Afisam Link Formular' >
                    <el-checkbox v-model='selectedObject.AfisamLinkFormular' true-label='1' false-label='0'> Afisam Link Formular </el-checkbox>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="LinkFormular" label='Link Formular' >
                    <el-input  class='full-width' v-model='selectedObject.LinkFormular' />
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <el-dialog
            append-to-body
            title="Image size"
            :visible.sync="showImageSizePopup"
            width="30%">

            <span>Image Width (procentual)</span>
            <el-input v-model="imageWidth" placeholder="Width"></el-input>

             <span slot="footer" class="dialog-footer">
                <el-button @click="showImageSizePopup = false">Cancel</el-button>
                <el-button type="primary" @click="addImage">Confirm</el-button>
            </span>
        </el-dialog>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import { Vue2TinymceEditor } from "vue2-tinymce-editor";       

    export default {
        name: "Evenimente_dialog",
        extends: BasePage,
        components: {
            Vue2TinymceEditor
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                imageWidth: '',
                imageUrl: '',
                showImageSizePopup: false,
                language: '',
                selectedObject: {
                    Tip: '' , TitleRo: '' , TitleEn: '' , SlugRo: '' , SlugEn: '' , SubtitleRo: '' , SubtitleEn: '' , DescriptionRo: '' , DescriptionEn: '' , Image: '' , uploadPreview_Image : '' , ImageCaptionRo: '' , ImageCaptionEn: '' , MetaDescriptionRo: '' , MetaDescriptionEn: '' , MetaKeywordsRo: '' , MetaKeywordsEn: '' , LinkExtern: '' , AfisamLinkFormular: '' , LinkFormular: '' ,                 },
                Info:{
                                    },
                rules: {
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("evenimente/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                    this.selectedObject.Published = this.selectedObject.Published == '1' ? true : false;

                }
            },
            async get_info(){
                var response = await this.post("evenimente/get_info_for_dialog" );
            },

            addImage(){
                this.showImageSizePopup = false;
                if(this.language == 'RO'){
                    this.selectedObject.ContentRo += `<img src="${this.imageUrl}" class='img-articol' style="object-fit: contain; width: ${this.imageWidth}%;" />`;
                }else {
                    this.selectedObject.ContentEn += `<img src="${this.imageUrl}" class='img-articol' style="object-fit: contain; width: ${this.imageWidth}%;" />`;
                }
            },

            handleUploadImageRoTinymce(response){
                setTimeout(() => {
                    
                }, 1000);
                this.showImageSizePopup = true;
                this.language = 'RO';
                this.imageUrl = response.Url;
                console.log(this.selectedObject.ContentRo);
            },

            handleUploadImageEnTinymce(response){
                this.showImageSizePopup = true;
                this.language = 'EN';
                this.imageUrl = response.Url;
                console.log(this.selectedObject.ContentEn);
            },

            handleUploadSuccess_Image(response){
                    this.selectedObject.Image = response.FileName;
                    this.selectedObject.uploadPreview_Image  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("evenimente/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        },
        watch: {
            'selectedObject.TitleRo': function(newVal){
                let slug_ro = settings.generate_slug(newVal);
                this.selectedObject.SlugRo = slug_ro;
            },
            'selectedObject.TitleEn': function(newVal){
                let slug_en = settings.generate_slug(newVal);
                this.selectedObject.SlugEn = slug_en;

            }
        }
    
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .imagePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

</style>